<template>
	<v-col id="tools" class="tools" style="height: 800px;overflow-y: auto;padding: 25px;">
		
			<v-col v-for="item in variants" :key="item.title">
				<div class="text-h6">{{item.title}}</div>
				<v-row justify="start">
					<v-col v-for="(variant, k) in item.val" :key="k" cols="auto">
						<v-card class="mx-auto" max-width="344" height='180' :variant="variant" @click="toProject(variant)">
							<v-row justify="start">
								<v-col cols="6">
									<!-- 左侧内容 -->
									<div class="text-h6 mb-1" style="height: 60px">{{ variant.name }}</div>
									<div class="text-caption" style="height: 90px;overflow: auto;">{{ variant.caption }}
									</div>
								</v-col>
								<v-col cols="6">
									<!-- 右侧内容 -->
									<v-img :src="require(`@/assets/tools/${variant.path}.png`)" :alt="variant.name"
										style="border-radius: 24px;"></v-img>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
	</v-col>
	
</template>

<script>
	
	import {variants} from '@/store/default'
	export default {
		components: {
		},
		data: () => ({
			inventive: {
				// 新建/修改内容
				projectName: '',
				description: '',
				projectId: '',
				imageUrl: null,
			},
			variants:[...variants]
			
		}),
		created() {},
		mounted: function() {},
		methods: {
			toProject(item) {
				const storedUsername = localStorage.getItem('Username');
				console.log(item,storedUsername)
				if(item.path == 'patent'){
					window.open('https://patents.qizhidao.com/', '_blank')
				}else if(item.path == 'DeepSeek'){
					const apiChatUrl = process.env.VUE_APP_API_CHAT_URL;
					const urlWithUsername = `${apiChatUrl}?username=${encodeURIComponent(storedUsername)}`;
					window.open(urlWithUsername, '_blank');
				}else{
					this.$router.push({
						path: `/one_triz/${item.path}`,
					})
				}
			}
		},
	}
</script>
<style scoped>
.container{
	height: 880px;
    overflow-y: auto;
    padding-bottom: 30px;
}
</style>